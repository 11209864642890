<template>
  <div
    v-if="show"
    ref="confimration_modal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="confirmModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital">
              Deposit Statement
            </span>
            <a
              class="
                modal__close modal__close--white
                d-flex
                align-items-center
                justify-content-between
              "
              @click="$emit('close')"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
          <span class="modal__header__subtitle text--capital"> </span>
        </div>
        <div class="modal__content">
          <div class="modal__body">
            <div class="account_item_head">
              <p class="account_item_head_text text--white">select date range</p>
            </div>

            <div
              class="form__item mt-5"
              style="display:flex;width:100%;"
            >
              <div class="mr-2" style="width:50%">
                <label class="form__label text--black text--capital mb-3">start date</label>
                <input
                  type="date"
                  class="input form__input form__input--lg r-font"
                  placeholder="Enter value"
                  v-model="startDate"
                  :max="dateLimit"
                  @change="clearEndDate"
                />
              </div>
              
              <div class="ml-2" style="width:50%">
                <label class="form__label text--black text--capital mb-3">end date</label>
                <input
                  type="date"
                  class="input form__input form__input--lg r-font"
                  placeholder="Enter value"
                  :min="startDate"
                  @change="checkEndDate"
                  v-model="endDate"
                />
              </div>
            </div>

            <div class="d-flex mt-5">
              <button
                class="
                  button
                  form__button form__button--sm
                  w-50
                  mr-2
                "
                @click="getDepositStatement"
              >
                {{ isLoading ? "loading..." : "export" }}
              </button>
              <button
                class="
                  button button--lg
                  modal__confirm__button modal__confirm__button--cancel
                  mr-2
                "
                @click="$emit('close')"
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ref="template" style="padding:20px; overflow:hidden">
      <div style="position:relative">
        <img alt="template-header" src="@/assets/img/template_header.png" style="width: 100%"/>
        <span style="position:absolute; color:white; left:760px; top:30px">
          <h1 style="font-size:1.5em;font-weight:700">Account Statement</h1>
        </span>
      </div>

      <div style="display:flex; padding-top:60px; width:100%">
        <div style="width:50%">
          <h1>{{ allActivities.accountName }}</h1>
          <hr style="border:3px solid orange; width: 162px"/>
        </div>
        <div style="display:flex; justify-content:flex-end; width:50%">
          <h3 style="font-weight:700">{{ startDate | moment }} to {{ endDate | moment }}</h3>
        </div>
      </div>

      <div style="display:flex; padding-top:40px;width:100%">
        <div style="display:flex; width:50%;padding-top:30px">
          <div>
            <p style="color:#696969; font-size:12px">Account Number:</p>
            <h3 style="font-weight:700">{{ allActivities.accountId }}</h3>
          </div>

          <div style="padding-left:20px">
            <p style="color:#696969; font-size:12px">Product:</p>
            <h3 style="font-weight:700">{{ allActivities.product }}</h3>
          </div>
        </div>
        <div style="display:flex; width:50%; justify-content:flex-end">
          <div style="display:flex;padding:30px;border:1px solid #f5f5f5;border-radius:10px;width:max-content;background:#d4d4d4">
            <div style="padding-right: 15px">
              <p style="color:#696969; font-size:12px">Opening Balance</p>
              <h3 style="font-weight:700">N{{ allActivities.openingBalance }}</h3>
            </div>
            <div style="border-left: 1px solid black;height: 50px"></div>
            <div style="padding-left:15px">
              <p style="color:#696969; font-size:12px">Closing Balance</p>
              <h3 style="font-weight:700">N{{ allActivities.closingBalance }}</h3>
            </div>
          </div>
        </div>
      </div>

      <div style="display:flex; padding-top:40px;width:100%">
        <div style="width:50%">
          <h2>Account Summary</h2>
          <div style="display:flex">
            <div>
              <p style="color:#696969; font-size:12px">Total Credit</p>
              <h3 style="font-weight:700">{{ allActivities.totalCredit }}</h3>
            </div>

            <div style="padding-left:20px">
              <p style="color:#696969; font-size:12px">Total Debit</p>
              <h3 style="font-weight:700">{{ allActivities.totalDebit }}</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="page__body" style="padding-top:60px;min-height:700px">
        <table class="customtable w-100" style="padding-left:15px">
          <thead style="background:#ffdecc;">
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Date
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Debit
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Credit
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Transaction Type
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Description
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(activity) in allActivities.transactions" :key="activity.savingsAccountKey">
              <td>
                <span
                  class="
                    table__body__text table__text
                    text--capital text--capital
                  "
                >
                  {{ activity.postedDate | moment }}</span
                >
              </td>
              <td>
                <span class="table__body__text table__text text--capital" style="word-break: break-all;"
                  >{{ activity.transactionMode.toLowerCase() !== "credit" ? activity.amount : 0 }}</span
                >
              </td>
              <td>
                <span
                  class="
                    table__body__text table__text
                    text--capital
                  "
                >
                  {{ activity.transactionMode.toLowerCase() === "credit" ? activity.amount : 0 }}</span
                >
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  activity.transactionType
                }}</span>
              </td>
              <td>
                <span 
                  class="
                    table__body__text table__text text--capital
                  "
                >{{
                  activity.description
                }}</span>
              </td>
              <td>
                <span 
                  class="
                    table__body__text table__text
                    text--capital text--capital
                  "
                >{{
                  activity.balance
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div style="position: relative;">
        <img alt="template-footer" src="@/assets/img/template_footer.png" style="width: 100%"/>
        <span style="position:absolute; color:white; width:100%; left:278px;top:30px">
          <h5 style="">Please address all enquiries to  Finance Limited, 177A, Ligali Ayorinde Street, Victoria Island, Lagos,</h5>
          <h5>Printed on: {{ currentDate }}</h5>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas"
import ApiService from "@/core/services/api.service";
export default {
  name: "ViewTransaction",
  props: {
    show: Boolean,
    close: Function,
    depositKey: String,
    // accountDetails: [Array, Object],
  },
  data() {
    return{
      startDate: "",
      endDate: "",
      currentDate: moment().format("DD MMMM, YYYY"),
      isLoading: false,
      allActivities: {},
      dateLimit: moment().format("YYYY-MM-DD"),
      // allActivities: {
      //   "accountName": "Oti Richard",
      //   "startDate": "01/10/2022 00:00:00",
      //   "endDate": "01/14/2022 00:00:00",
      //   "openingBalance": 0,
      //   "closingBalance": 7400,
      //   "product": "Mark Pro",
      //   "totalCredit": 4000,
      //   "totalDebit": 0,
      //   "accountId": "120987654",
      //   "narration": null,
      //   "transactions": [
      //     {
      //       "savingsAccountKey": 1,
      //       "transactionType": "transfer",
      //       "postedDate": "2022-01-11T18:45:47.577",
      //       "balance": 0,
      //       "amount": 200,
      //       "destionationAccount": null,
      //       "postedStatus": null,
      //       "note": null,
      //       "description": "you",
      //       "entryDate": "2022-01-11T18:45:47.577",
      //       "transactionMode": "CREDIT",
      //       "transactionID": "22823"
      //     },
      //     {
      //       "savingsAccountKey": 2,
      //       "transactionType": "withdrawal",
      //       "postedDate": "2022-01-11T18:45:47.61",
      //       "balance": -12000,
      //       "amount": 100,
      //       "destionationAccount": null,
      //       "postedStatus": null,
      //       "note": null,
      //       "description": "me",
      //       "entryDate": "2022-01-11T18:45:47.61",
      //       "transactionMode": "CREDIT",
      //       "transactionID": "22839"
      //     },
      //     {
      //       "savingsAccountKey": 3,
      //       "transactionType": "deposit",
      //       "postedDate": "2022-01-11T18:46:49.74",
      //       "balance": -8500,
      //       "amount": 4000,
      //       "destionationAccount": null,
      //       "postedStatus": null,
      //       "note": null,
      //       "description": "what",
      //       "entryDate": "2022-01-11T18:46:49.74",
      //       "transactionMode": "CREDIT",
      //       "transactionID": "22836"
      //     },
      //   ]
      // }
    }
  },
  methods: {
    getDepositStatement() {
      if(this.startDate && this.endDate) {
        this.isLoading = true;
        ApiService.get(`AccountStatement/AccountStatementByDate?savingsAccountKey=${this.depositKey}&pageIndex=1&pageSize=10000&startDate=${this.startDate}&endDate=${this.endDate}`)
          .then(response => {
            if(response.data.data) {
              this.allActivities = response.data.data;
              setTimeout(() => {
                this.isLoading = false;
                this.downloadPDF();
              }, 5000)
            } else {
              this.isLoading = false;
              this.$emit("statementAlert", { type: "warning", message: "The selected date range does not have account statement." })
            }
          })
          .catch(err => {
            this.isLoading = false;
            this.$emit("statementAlert", { type: "error", message: err.response.data.message })
          })
      }else {
        this.$emit("statementAlert", { type: "warning", message: "Select start date and end date to continue." })
      }
    },
    clearEndDate() {
      if(moment(this.startDate).isAfter(this.dateLimit)) {
        this.$emit("statementAlert", { type: "warning", message: "Start date cannot be greater than today's date." });
        this.startDate = "";
      }
    },
    checkEndDate() {
      if(!moment(this.endDate).isBetween(this.startDate, this.dateLimit, null, "[]")) {
        this.$emit("statementAlert", { type: "warning", message: "End date must be greater than start date and less than today's date." });
        this.endDate = "";
      }
    },
    downloadPDF() {
      this.isLoading = true;
      var doc = new jsPDF('pt', 'mm', [1700, 1700]);
      // var pdfjs = document.querySelector('#statement-template');

      // doc.html(pdfjs, {
      //     callback: function(doc) {
      //         doc.save("output.pdf");
      //     },
      //     x: 10,
      //     y: 10
      // });

      // doc.output('dataurlnewwindow');
      html2canvas(this.$refs.template, {
          width: doc.internal.pageSize.getWidth(),
          height: doc.internal.pageSize.getHeight()
        }).then((canvas) => {
          const img = canvas.toDataURL("image/png");

          doc.addImage(img, "PNG", 140, 10, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
          doc.save("deposit_statement.pdf");
          this.$emit("statementAlert", { type: "success", message: "Statement sucessfully downloaded." })
          this.isLoading = false;
          this.$emit("close")
          this.startDate = "";
          this.endDate = "";
        })
      // this.isLoading = false;
      // this.$emit("close")
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },

};
</script>

<style scoped>
.overlap {
  word-wrap: break-word;
  width: 90%;
}
</style>
